<script lang="ts">
  import LL from '$i18n/i18n-svelte';
  import { fade } from 'svelte/transition';
  import IndexPost from '$lib/components/index_post.svelte';
  import { paginatedPosts, totalPages, currentPage, setPage } from '$lib/stores/pageState';
  import { onMount } from 'svelte';
  import { goto } from '$app/navigation';

  let className: string | undefined = undefined;
  export { className as class };

  let loaded = false;
  onMount(() => {
    loaded = true;
  });

  function getPageRange(current: number, total: number, show: number): number[] {
    if (total <= show) return Array.from({ length: total }, (_, i) => i + 1);

    let start = Math.max(current - Math.floor(show / 2), 1);
    let end = start + show - 1;

    if (end > total) {
      end = total;
      start = Math.max(end - show + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  function handlePageChange(newPage: number) {
    console.log('handlePageChange 被調用：', newPage);
    setPage(newPage);
    // 更新 URL，但不替換歷史記錄
    const url = new URL(window.location.href);
    url.searchParams.set('page', newPage.toString());
    goto(url.toString(), { replaceState: false, keepFocus: true, noScroll: true });

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }
</script>

<main
  id="index-posts"
  class="flex flex-col items-center py-4 gap-6 {className ?? ''}"
  itemscope
  itemprop="mainEntityOfPage"
  itemtype="https://schema.org/Blog">
  {#if loaded}
    {#key $paginatedPosts}
      {#if $paginatedPosts.length === 0}
        <div
          class="h-[20rem] flex items-center justify-center"
          in:fade|global={{ duration: 300, delay: 300 }}
          out:fade|global={{ duration: 300 }}>
          <h2 class="text-3xl">{$LL.NoPostFound()}</h2>
        </div>
      {:else}
        <!-- 註釋掉年份相關邏輯 -->
        <!-- {@const years = [new Date().getFullYear()]} -->
        {#each $paginatedPosts as p, index}
          <!-- 註釋掉年份檢查和分隔線 -->
          <!-- {@const year = new Date(p.published).getFullYear()} -->
          <!-- {#if !isNaN(year) && !years.includes(year)}
            <div
              in:fade|global={{ duration: 300, delay: 300 }}
              out:fade|global={{ duration: 300 }}
              class="year-divider my-4 md:my-0">
              {years.push(year) && year}
            </div>
          {/if} -->
          <IndexPost data={p} {index} />
        {/each}
      {/if}
    {/key}

    {#if $totalPages > 1}
      <div class="pagination flex flex-wrap justify-center items-center mt-8 gap-2">
        <button
          on:click={() => handlePageChange($currentPage - 1)}
          disabled={$currentPage === 1}
          class="px-3 py-1 bg-gray-200 rounded text-sm sm:text-base sm:px-4 sm:py-2">
          上一頁
        </button>

        {#each getPageRange($currentPage, $totalPages, 5) as page}
          <button
            on:click={() => handlePageChange(page)}
            class="px-2 py-1 rounded text-sm sm:text-base {$currentPage === page
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200'}">
            {page}
          </button>
        {/each}

        <button
          on:click={() => handlePageChange($currentPage + 1)}
          disabled={$currentPage === $totalPages}
          class="px-3 py-1 bg-gray-200 rounded text-sm sm:text-base sm:px-4 sm:py-2">
          下一頁
        </button>
      </div>
    {/if}
  {:else}
    <div
      class="h-[20rem] flex flex-col items-center justify-center gap-4"
      in:fade|global={{ duration: 300, delay: 300 }}
      out:fade|global={{ duration: 300 }}>
      <h2 class="text-3xl">{$LL.LoadingPosts()}</h2>
      <div class="i-line-md-loading-twotone-loop !h-16 !w-16" />
    </div>
  {/if}
</main>

<style lang="scss">
  // 註釋掉年份分隔線的樣式
  /* .year-divider {
    --at-apply: 'my-4 h-4 whitespace-nowrap flex flex-row items-center self-stretch md:mx-12';
    &:before {
      content: '';
      --at-apply: 'bg-black dark:bg-white op25 flex-grow h-0.5 w-full rounded-2xl';
    }
    &:after {
      content: '';
      --at-apply: 'bg-black dark:bg-white op25 flex-grow h-0.5 w-full rounded-2xl';
    }
    &:not(:empty) {
      --at-apply: 'gap-4';
    }
  } */
</style>
