<script lang="ts">
  import { onMount, afterUpdate } from 'svelte';
  import { page } from '$app/stores';
  import { goto } from '$app/navigation';
  import { browser } from '$app/environment';
  import { fly } from 'svelte/transition';
  import IndexProfile from '$lib/components/index_profile.svelte';
  import IndexPosts from '$lib/components/index_posts.svelte';
  import Tags from '$lib/components/tags_root.svelte';
  import { tagsCur, tagsShowMobile, tagsShowDesktop } from '$stores/tags';
  import { postsShow } from '$stores/posts';
  import { siteConfig } from '$config/site';
  import { query, result, searching } from '$lib/search/stores';
  import { currentPage, handlePopState, syncUrlToState } from '$lib/stores/pageState';

  let lastQuery = '';
  let lastPage = 1;

  function handleUrlChange() {
    if (browser && $page.url.pathname === '/') {
      const currentQuery = $page.url.searchParams.get('query') || '';
      const currentPageNum = parseInt($page.url.searchParams.get('page') || '1', 10);
      // console.log('URL 變化：', { currentQuery, currentPageNum, lastQuery, lastPage });

      if (currentQuery !== lastQuery || currentPageNum !== lastPage) {
        // console.log('檢測到變化');
        lastQuery = currentQuery;
        lastPage = currentPageNum;

        syncUrlToState($page.url);
      }
    }
  }

  $: if (browser && $page.url.pathname === '/') {
    const currentQuery = $page.url.searchParams.get('query') || '';
    const currentPageNum = parseInt($page.url.searchParams.get('page') || '1', 10);
    console.log('URL 變化：', { currentQuery, currentPageNum, lastQuery, lastPage });

    if (currentQuery !== lastQuery || currentPageNum !== lastPage) {
      // console.log('檢測到變化');
      lastQuery = currentQuery;
      lastPage = currentPageNum;

      syncUrlToState($page.url);
    }
  }

  afterUpdate(() => {
    if ($result !== undefined) {
      postsShow.filter();
    }
  });

  onMount(() => {
    if (browser) {
      window.addEventListener('popstate', handlePopState);

      if ($page.url.pathname === '/') {
        // 只在首頁執行
        tagsCur.init();
        postsShow.init();

        syncUrlToState($page.url);

        // 處理 URL 參數
        $page.url.searchParams.forEach((v, k) => {
          k = decodeURI(k);
          if (k.match(/^tags(-.*)?/)) {
            k = k.replace(/^tags-/, '');
            v.split(',').forEach((v) => {
              tagsCur.add(k, v);
            });
          }
        });
        const queryParam = $page.url.searchParams.get('query');
        if (queryParam) {
          query.set(queryParam);
        }
      }

      // 添加這行來初始化 handleUrlChange
      handleUrlChange();
    }
  });
  $: if (browser && $page.url.pathname === '/') {
    handleUrlChange();
  }
</script>

<svelte:head>
  <title>{siteConfig.title}{$currentPage > 1 ? ` - Page ${$currentPage}` : ''}</title>
  <meta name="description" content={siteConfig.description} />
  <link rel="canonical" href={new URL($page.url.pathname, siteConfig.url).href} />

  <!-- OpenGraph -->
  <meta property="og:site_name" content={siteConfig.title} />
  <meta property="og:locale" content={siteConfig.lang} />
  <meta property="og:type" content="website" />

  <meta property="og:title" content="{siteConfig.title}{$currentPage > 1 ? ` - Page ${$currentPage}` : ''}" />
  <meta name="twitter:title" content="{siteConfig.title}{$currentPage > 1 ? ` - Page ${$currentPage}` : ''}" />

  <meta property="og:description" content={siteConfig.description} />
  <meta name="twitter:description" content={siteConfig.description} />

  <meta property="og:url" content={new URL($page.url.pathname, siteConfig.url).href} />
  <meta property="twitter:url" content={new URL($page.url.pathname, siteConfig.url).href} />

  <meta property="og:image" content={new URL(siteConfig.cover, siteConfig.url).href} />
  <meta name="twitter:image" content={new URL(siteConfig.cover, siteConfig.url).href} />
  <meta name="twitter:card" content="summary_large_image" />
</svelte:head>

{#if $tagsShowMobile}
  <div
    in:fly|global={{ x: -100, y: -100, duration: 300, delay: 300 }}
    out:fly|global={{ x: -100, y: -100, duration: 300 }}
    class="mx6 my4 xl:hidden">
    <Tags class="flex flex-col min-w-[12rem]" />
  </div>
{:else}
  <div
    in:fly|global={{ y: 100, duration: 300, delay: 300 }}
    out:fly|global={{ y: 100, duration: 300 }}
    itemscope
    itemtype="https://schema.org/Blog"
    itemprop="blog"
    class="flex flex-nowrap justify-center flex-col items-center xl:hidden">
    <div class="h-feed min-h-50vh flex-none w-full">
      <IndexPosts />
    </div>
  </div>
{/if}

<div
  itemscope
  itemtype="https://schema.org/Blog"
  itemprop="blog"
  class="flex-nowrap justify-center flex-col items-center hidden xl:(flex flex-row items-stretch)">
  <div
    in:fly|global={{ x: -100, y: -100, duration: 300, delay: 300 }}
    out:fly|global={{ x: -100, y: 100, duration: 300 }}
    class="min-w-12rem max-w-screen-md flex-1 relative">
  </div>

  <div
    in:fly|global={{ y: 100, duration: 300, delay: 300 }}
    out:fly|global={{ y: -100, duration: 300 }}
    class="h-feed min-h-50vh flex-none w-full md:(rounded-2xl w-[50rem] mx2)">
    <IndexPosts />
  </div>

  <div
    in:fly|global={{ x: 100, y: -100, duration: 300, delay: 300 }}
    out:fly|global={{ x: 100, y: 100, duration: 300 }}
    class="min-w-12rem max-w-screen-md flex-1 relative mr6">
    {#if $tagsShowDesktop}
      <Tags class="hidden max-w-[20rem] my4 rounded-2xl p4 xl:(flex flex-col min-w-[12rem] sticky top-[4rem])" />
    {/if}
  </div>
</div>
